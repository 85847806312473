import {Routes} from '@angular/router';

export const content: Routes = [
  {
    path: 'home',
    loadChildren: () => import('../../layouts/components/home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'm',
    loadChildren: () => import('../../layouts/components/meeting-screen/meeting-screen.module').then(m => m.MeetingScreenModule),
  },
  {
    path: 'r',
    loadChildren: () => import('../../layouts/components/playback-screen/playback-screen.module').then(m => m.PlaybackScreenModule),
  },
  {
    path: 'profile',
    loadChildren: () => import('../../layouts/components/profile/profile.module').then(m => m.ProfileModule),
  },
];

export const landing: Routes = [
  {
    path: 'landing',
    loadChildren: () => import('../../landing/pages-list/Landing/Landing.module').then(m => m.LandingModule)
  }
];
