import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';

@Injectable()
export class AppUrl {


  static get USER_URL(): string {
    return environment.appUrl + 'user/';
  }

  static get LOGIN(): string {
    return AppUrl.USER_URL + 'login';
  }

  static get REGISTER(): string {
    return AppUrl.USER_URL + 'register';
  }

  static get SELF(): string {
    return AppUrl.USER_URL + 'self';
  }

  static INSTANT_MEETING(): string {
    return environment.appUrl + 'meeting/instant';
  }

  static MEETING_DETAIL(id: string): string {
    return environment.appUrl + 'meeting/detail/'+id;
  }

  static MEETING(id?: any): string {
    if (id) return environment.appUrl + 'meeting/'+id;
    else return environment.appUrl + 'meeting';
  }

  static JOIN_MEETING(id: any): string {
    return environment.appUrl + 'meeting/join/'+id;
  }

  static CURRENT_DATE_TIME(): string {
    return environment.appUrl + 'common/now';
  }

  static VERIFY_MEETING_PASSWORD(uuid: any): string {
    return environment.appUrl + 'meeting/verify-password/'+uuid;
  }

  static RECORDING(uuid: any, recordingId: any): string {
    return environment.appUrl + 'meeting/recording/'+uuid+'/'+recordingId;
  }

  static CHAT(uuid: any): string {
    return environment.appUrl + 'meeting/chat/'+uuid;
  }

  static INVITE(uuid: any): string {
    return environment.appUrl + 'meeting/invite/'+uuid;
  }

  static FILE_UPLOAD(): string {
    return environment.appUrl + 'common/upload';
  }

  static UPDATE_PROFILE(): string {
    return environment.appUrl + 'user/update';
  }

  static UPDATE_PASSWORD(): string {
    return environment.appUrl + 'user/update-password';
  }

}

