import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {VexPopoverService} from '@vex/components/vex-popover/vex-popover.service';
import {ToolbarUserDropdownComponent} from './toolbar-user-dropdown/toolbar-user-dropdown.component';
import {MatIconModule} from '@angular/material/icon';
import {MatRippleModule} from '@angular/material/core';
import {JsonPipe, NgClass, NgIf} from '@angular/common';
import {StorageService} from '../../../../shared/services/storage.service';
import {AuthService} from '../../../../shared/services/auth.service';
import {DataService} from '../../../../shared/services/data.service';
import {ProfileImageService} from '../../../../shared/services/profile-image.service';
import {MatDialog, MatDialogContent} from '@angular/material/dialog';

@Component({
  selector: 'vex-toolbar-user',
  templateUrl: './toolbar-user.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./toolbar-user.component.scss'],
  standalone: true,
  imports: [
    MatRippleModule,
    MatIconModule,
    JsonPipe,
    NgIf,
    MatDialogContent,
    NgClass
  ],
  providers: [
    StorageService
  ]
})
export class ToolbarUserComponent implements OnInit {
  @ViewChild('callingModal') callingModal: TemplateRef<any>;
  @ViewChild('receivingModal') receivingModal: TemplateRef<any>;
  dropdownOpen: boolean = false;
  user: any;
  userImageUrl: string;
  hideCallingIcon: any = true;
  hideIncomingCallIcon: any = true;
  callingData: any;
  receivingData: any;
  displayTimer: any = {
    'hours': 0,
    'minutes': 0,
    'seconds': 0,
  };
  displayTimerIncoming: any = {
    'hours': 0,
    'minutes': 0,
    'seconds': 0,
  };
  activity: any = {
    'text': '',
    'color': ''
  };
  subActivity: any = {
    'text': '',
    'color': ''
  };
  activityIncoming: any = {
    'text': '',
    'color': ''
  };
  subActivityIncoming: any = {
    'text': '',
    'color': ''
  };
  callStatus: any;
  callStatusIncoming: any;
  // teleCmiCredentials: any = AppConstants.teleCmiCredentials;
  teleCmiCredentials: any;
  timerId: any;
  timerIdIncoming: any;
  isCallActive: boolean = true;
  actionKeys: any = {
    'hold': 'SHOW_AND_ACTIVE',
    'unhold': 'SHOW_AND_ACTIVE',
    'mute': 'SHOW_AND_ACTIVE',
    'unmute': 'SHOW_AND_ACTIVE',
    'call': 'SHOW_AND_ACTIVE'
  };
  actionKeysIncoming: any = {
    'receive': 'SHOW_AND_ACTIVE',
    'hold': 'SHOW_AND_ACTIVE',
    'unhold': 'SHOW_AND_ACTIVE',
    'mute': 'SHOW_AND_ACTIVE',
    'unmute': 'SHOW_AND_ACTIVE',
    'call': 'SHOW_AND_ACTIVE'
  };
  private incomingAudio: HTMLAudioElement;
  private outgoingAudio: HTMLAudioElement;

  constructor(
    private popover: VexPopoverService,
    private cd: ChangeDetectorRef,
    private authService: AuthService,
    private imageService: ProfileImageService,
    protected dialog: MatDialog,
    private dataService: DataService,
  ) {
    this.authService.self().subscribe(data => {
      if (data && data._id) {
        this.user = data;
        this.userImageUrl = this.user?.bucketUrl + this.user?.profileId?.partialUrl;
        this.cd.detectChanges();
      }
    });

    this.imageService.getData().subscribe(data => {
      this.userImageUrl = data;
      this.cd.detectChanges();
    });

  }

  ngOnInit() {
    this.dataService.message$.subscribe(data => {
      if (data && data.type === 'profileImage') {
        this.user.profileId.partialUrl = data.data;
        this.userImageUrl = this.user?.bucketUrl + this.user?.profileId?.partialUrl;
      }

      if (data && data.type === 'profile') {
        this.user = data.data
      }
      this.cd.detectChanges();
    })
  }


  showPopover(originRef: HTMLElement) {
    this.dropdownOpen = true;
    this.cd.markForCheck();

    const popoverRef = this.popover.open({
      content: ToolbarUserDropdownComponent,
      origin: originRef,
      offsetY: 12,
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top'
        }
      ]
    });

    popoverRef.afterClosed$.subscribe(() => {
      this.dropdownOpen = false;
      this.cd.markForCheck();
    });
    this.getSelf();
  }

  getSelf() {
    this.authService.self().subscribe(data => {
      if (data && data.id) {
        this.user = data;
        this.cd.detectChanges();
      }
    });
  }

  openCallingModal(): void {
    this.dialog.open(this.callingModal, {
      width: '30vw',
      minWidth: '350px'
    });
  }

  openReceivingModal(): void {
    this.dialog.open(this.receivingModal, {
      width: '30vw',
      minWidth: '350px'
    });
  }

  closeModal(): void {
    this.dialog.closeAll();
  }
}
