import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppUrl } from '../constants/app-url';
import { HttpClient } from '@angular/common/http';
import { AlertService } from './alert.service';
import { GlobalService } from './global.service';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {

  constructor(public http: HttpClient,
              public alertService: AlertService,
              public globalService: GlobalService) {
    super(http, alertService, globalService);
  }

  update(data: any): Observable<any> {
    return this.putRequest(AppUrl.UPDATE_PROFILE(), data);
  }

  updatePassword(data: any): Observable<any> {
    return this.putRequest(AppUrl.UPDATE_PASSWORD(), data);
  }

}
