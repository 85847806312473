import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Observable, Subject} from 'rxjs';
import {AlertService} from './alert.service';
import {Router} from '@angular/router';
import {GlobalService} from './global.service';
import {AppUrl} from '../constants/app-url';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseService {
  private profileImageSubject = new Subject<string>();
  profileImage$ = this.profileImageSubject.asObservable();

  constructor(public http: HttpClient, public alertService: AlertService, private router: Router, public globalService: GlobalService) {
    super(http, alertService, globalService);
  }

  login(data: any): Observable<any> {
    return this.postRequest(AppUrl.LOGIN, data);
  }

  register(data: any): Observable<any> {
    return this.postRequest(AppUrl.REGISTER, data);
  }

  self(params = {}): Observable<any> {
    return this.getRequest(AppUrl.SELF, params);
  }

}
