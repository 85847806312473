import {ChangeDetectorRef, Component, TemplateRef, ViewChild} from '@angular/core';
import {VexLayoutService} from '@vex/services/vex-layout.service';
import {combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Router, RouterLink, RouterOutlet} from '@angular/router';
import {VexConfigService} from '@vex/config/vex-config.service';
import {VexSidebarComponent} from '@vex/components/vex-sidebar/vex-sidebar.component';
import {AsyncPipe, NgIf, NgTemplateOutlet} from '@angular/common';
import {SidenavComponent} from '../components/sidenav/sidenav.component';
import {FooterComponent} from '../components/footer/footer.component';
import {ConfigPanelToggleComponent} from '../components/config-panel/config-panel-toggle/config-panel-toggle.component';
import {ConfigPanelComponent} from '../components/config-panel/config-panel.component';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import {BaseLayoutComponent} from '../base-layout/base-layout.component';
import {MatDrawerMode, MatSidenavModule} from '@angular/material/sidenav';
import {VexProgressBarComponent} from '@vex/components/vex-progress-bar/vex-progress-bar.component';
import {VexConfig} from '@vex/config/vex-config.interface';
import {StorageService} from "../../shared/services/storage.service";
import {AuthService} from "../../shared/services/auth.service";
import {GlobalService} from "../../shared/services/global.service";
import { VexPopoverService } from '@vex/components/vex-popover/vex-popover.service';
import {MatButton} from "@angular/material/button";
import {MatCardHeader, MatCardTitle} from "@angular/material/card";
import {MatIcon} from "@angular/material/icon";
import {ToolbarUserComponent} from "../components/toolbar/toolbar-user/toolbar-user.component";
import {NavigationComponent} from "../components/navigation/navigation.component";
import {
  ToolbarNotificationsComponent
} from "../components/toolbar/toolbar-notifications/toolbar-notifications.component";
import {NavigationItemComponent} from "../components/navigation/navigation-item/navigation-item.component";
import {ToolbarComponent} from "../components/toolbar/toolbar.component";
import {DataService} from "../../shared/services/data.service";

@Component({
  selector: 'vex-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  imports: [
    BaseLayoutComponent,
    NgIf,
    AsyncPipe,
    SidenavComponent,
    FooterComponent,
    ConfigPanelToggleComponent,
    VexSidebarComponent,
    ToolbarUserComponent,
    NavigationItemComponent,
    ToolbarNotificationsComponent,
    ToolbarUserComponent,
    NavigationComponent,
    ConfigPanelComponent,
    MatDialogModule,
    MatSidenavModule,
    NgTemplateOutlet,
    RouterOutlet,
    VexProgressBarComponent,
    MatButton,
    MatCardHeader,
    MatCardTitle,
    MatIcon,
    RouterLink,
    ToolbarComponent
  ],
  providers: [
    AuthService,
    GlobalService
  ],
  standalone: true
})
export class LayoutComponent {
  config$: Observable<VexConfig> = this.configService.config$;
  sidenavCollapsed$: Observable<boolean> = this.layoutService.sidenavCollapsed$;
  sidenavDisableClose$: Observable<boolean> = this.layoutService.isDesktop$;
  sidenavFixedInViewport$: Observable<boolean> =
    this.layoutService.isDesktop$.pipe(map((isDesktop) => !isDesktop));
  sidenavMode$: Observable<MatDrawerMode> = combineLatest([
    this.layoutService.isDesktop$,
    this.configService.select((config) => config.layout)
  ]).pipe(
    map(([isDesktop, layout]) =>
      !isDesktop || layout === 'vertical' ? 'over' : 'side'
    )
  );
  sidenavOpen$: Observable<boolean> = this.layoutService.sidenavOpen$;
  configPanelOpen$: Observable<boolean> = this.layoutService.configPanelOpen$;
  quickpanelOpen$: Observable<boolean> = this.layoutService.quickpanelOpen$;

  constructor(
    private readonly layoutService: VexLayoutService,
    private readonly configService: VexConfigService,
    private router: Router,
    private authService: AuthService,
    private globalService: GlobalService,
    private popover: VexPopoverService,
    private cd: ChangeDetectorRef
  ) {
    this.reloadSelf();
  }

  reloadSelf() {
    this.authService.self().subscribe((data) => {
      this.globalService.setSelf({
        name: data.name,
        email: data.email,
        contact: data.contact,
        userId: data._id,
        username: data.username,
        userType: data.userType,
      });
      return;
    }, e => {
      StorageService.clearAll();
      this.router.navigateByUrl('auth/login');
    });

  }

  onSidenavClosed(): void {
    this.layoutService.closeSidenav();
  }

  onQuickpanelClosed(): void {
    this.layoutService.closeQuickpanel();
  }
}
