import {Injectable} from '@angular/core';
import {StorageService} from './storage.service';
import {BehaviorSubject, Subject} from 'rxjs';
//import * as moment from 'moment';

declare var $: any;

@Injectable(
  {
    providedIn: 'root'
  }
)
export class GlobalService {
  public user: BehaviorSubject<any> = new BehaviorSubject(undefined);
  public self: BehaviorSubject<any> = new BehaviorSubject(undefined);
  public accessToken: BehaviorSubject<string> = new BehaviorSubject('');
  // @ts-ignore
  public permissions: BehaviorSubject<string> = new BehaviorSubject(undefined);
  // @ts-ignore
  public viewRefresh: BehaviorSubject<string> = new BehaviorSubject(undefined);
  public reloadSelf: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isSessionExist: Subject<any> = new Subject();
  public notification = new BehaviorSubject(undefined);
  notificationObservable = this.notification.asObservable();
  viewRefresh$ = this.viewRefresh.asObservable();
  self$ = this.self.asObservable();

  constructor() {
    if (StorageService.getItem('accessToken')) {
      this.setAccessToken(StorageService.getItem('accessToken'));
    }
    /*if (!StorageService.getItem('language')) {
      StorageService.setItem('language', 'EN');
    }*/
    /*this.pubnub.init({
        publishKey: 'pub-c-3cd375ad-b742-4f48-a4d1-b5ef6b94722e',
        subscribeKey: 'sub-c-e36efe14-5559-11ea-b828-26d2a984a2e5'
    });*/
    // this.getPermissions();
  }

  setSelf(self: any) {
    StorageService.setItem('self', self);
    this.self.next(self);
  }

  public setAccessToken(item: any) {
    StorageService.setItem('accessToken', item);
    this.accessToken.next(item);
  }

  public setIsSessionExist() {
    // @ts-ignore
    this.isSessionExist.next();
  }

  public setViewRefresh(value: any) {
    this.viewRefresh.next(value);
  }

  public getAccessToken() {
    return StorageService.getItem('accessToken');
  }

  public getUserType(){
    return StorageService.getItem('self').userType;
  }

  public getSelf(){
    return StorageService.getItem('self');
  }
}

/*export class MomentService {
  public now = moment;

  constructor() {

  }

  getDatepickerDate(date: any) {
    const array = this.now(date).format('YYYY-MM-DD').split('-');
    return {
      year: parseInt(array[0]),
      month: parseInt(array[1]),
      day: parseInt(array[2])
    };
  }

  public getParsedDate(date: any, isRequested = false) {
    if (isRequested) {
      return date ? this.getRequestDateFormat(new Date(`${date['month']}-${date['day']}-${date['year']}`)) : '';
    }
    return date ? this.now(new Date(`${date['month']}-${date['day']}-${date['year']}`)).format('YYYY-MM-DD') : '';
  }

  public getParsedDateTime(date: any, isRequested = false) {
    if (isRequested) {
      return date ? this.getRequestDateFormat(new Date(`${date['month']}-${date['day']}-${date['year']}`)) : '';
    }
    return date ? this.now(new Date(`${date['month']}-${date['day']}-${date['year']}`)) : '';
  }

  public getRequestDateFormat(date = new Date(), onlyDate = false) {
    if (onlyDate) {
      return this.now(date).subtract({minutes: this.now().utcOffset()}).format('YYYY-MM-DD');
    }
    return this.now(date).subtract({minutes: this.now().utcOffset()}).format('YYYY-MM-DD HH:mm:ss');
  }

  public parse(date?: any, format?: any) {
    if (format) {
      return date ? this.now(date).add({minutes: this.now().utcOffset()}).format(format) : new Date();
    }
    return date ? this.now(date).add({minutes: this.now().utcOffset()}) : new Date();
  }

  public getCounterString(from = new Date(), expiry: any) {
    const fromDate = from.getTime();
    const expiryDate = expiry.getTime();
    return Math.max(0, expiryDate - fromDate);
  }

  public getBasicDateTimeString(date: any) {
    return this.now(date).subtract({minutes: this.now().utcOffset()}).format('DD-MM-YYYY kk:mm A');
  }

  public getDateOnly(date: any) {
    return this.now(date).format('DD-MM-YYYY');
  }

  public yearMonthDayDate(date: any) {
    return this.now(date).format('YYYY-MM-DD');
  }

  public fromNow(date?: any, doOffset?: any) {
    if (doOffset) {
      return date ? this.now(date).add({minutes: this.now().utcOffset()}).fromNow() : this.now().fromNow();
    } else {
      return date ? this.now(date).fromNow() : this.now().fromNow();
    }
  }

  getDisplayDate(date: any) {
    return this.now(new Date(date)).format('MMM DD, YYYY');
  }

}*/

@Injectable()
export class MetaService {
  constructor() {
    /*StaticEventsService.refreshContent$.subscribe((e) => {
        if (e) {
            setTimeout(() => {
                this.refreshMeta();
            }, 100);
        }
    });*/
  }

  // getLanguageObject(): Observable<boolean> {
  //   // Add request for storing the translation
  //
  //   return ;
  // };

  refreshMeta() {
  }
}
