import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {throwError} from 'rxjs';
import {catchError, map, takeUntil} from 'rxjs/operators';
import {AlertService} from './alert.service';
import {StorageService} from './storage.service';
import {GlobalService} from './global.service';
import {UtilsService} from "./utils.service";

// import {takeUntil} from 'rxjs/internal/operators';

@Injectable()
export class BaseService {
  constructor(public http: HttpClient, public alertService: AlertService, public globalService: GlobalService) {
  }

  public joinMessages(array: any) {
    let string = '';
    array.forEach((message: any) => {
      string = string + message;
    });
    return string;
  }

  public postRequest(url: any, data = {}, handler = {success: null, failure: null}, alertHide?: any) {
    data = UtilsService.deleteEmptyKeys(data);
    return this.postRequestWithoutDataDeletion(url, data, handler, alertHide);
  }

  public postRequestWithoutDataDeletion(url: any, data = {}, handler = {
    success: null,
    failure: null
  }, alertHide?: any) {
    const headers: HttpHeaders = new HttpHeaders()
      .set("device", "WEB")
      .set("Authorization", "Bearer " + this.globalService.getAccessToken());
    return this.http.post(url, data, {headers: headers}).pipe(takeUntil(this.globalService.isSessionExist), map((res: any) => {
        if (res && res['message'] && !alertHide) {
          this.alertService.showSuccess(res['message']);
        }
        return res['data'];
      }), catchError(err => {
        return this.handleError(err);
      })
    );
  }


  public patchRequest(url: any, data = {}) {
    data = UtilsService.deleteEmptyKeys(data);
    return this.http.patch(url, data).pipe(
      takeUntil(this.globalService.isSessionExist),
      map((res: any) => {
        if (res['message']) {
          this.alertService.showSuccess(res['message']);
        }
        return res['data'];
      }),
      catchError(err => {
        return this.handleError(err);
      })
    );
  }

  public deleteRequest(url: any, data = {}) {
    const headers: HttpHeaders = new HttpHeaders()
      .set("device", "WEB")
      .set("Authorization", "Bearer " + this.globalService.getAccessToken());
    data = UtilsService.deleteEmptyKeys(data);
    return this.http.delete(url, {params: data, headers: headers}).pipe(
      takeUntil(this.globalService.isSessionExist),
      map((res: any) => {
        if (res['message']) {
          this.alertService.showSuccess(res['message']);
        }
        return res['data'];
      }),
      catchError(err => {
        return this.handleError(err);
      })
    );
  }

  public putRequest(url: any, data = {}, alertHide?) {
    data = UtilsService.deleteEmptyKeys(data);
    if (alertHide) {
      return this.putRequestWithoutDataDeletionAndAlertMessage(url, data);
    } else {
      return this.putRequestWithoutDataDeletion(url, data);
    }
  }

  public putRequestWithoutDataDeletion(url: any, data = {}) {
    const headers: HttpHeaders = new HttpHeaders()
      .set("device", "WEB")
      .set("Authorization", "Bearer " + this.globalService.getAccessToken());
    return this.http.put(url, data, {headers: headers}).pipe(
      takeUntil(this.globalService.isSessionExist),
      map((res: any) => {
        if (res['message']) {
          this.alertService.showSuccess(res['message']);
        }
        return res['data'];
      }),
      catchError(err => {
        return this.handleError(err);
      })
    );
  }

  public putRequestWithoutDataDeletionAndAlertMessage(url: any, data = {}) {
    const headers: HttpHeaders = new HttpHeaders()
      .set("device", "WEB")
      .set("Authorization", "Bearer " + this.globalService.getAccessToken());
    return this.http.put(url, data, {headers: headers}).pipe(
      takeUntil(this.globalService.isSessionExist),
      map((res: any) => {
        return res['data'];
      })
    );
  }

  /*public putRequestWithoutAlertMessage(url: any, data = {}) {
    data = UtilsService.deleteEmptyKeys(data);
    return this.putRequestWithoutDataDeletionAndAlertMessage(url, data);
  }

  public putRequestWithoutDataDeletionAndAlertMessage(url: any, data = {}) {
    const headers: HttpHeaders = new HttpHeaders()
      .set("device", "WEB")
      .set("Authorization", "Bearer " + this.globalService.getAccessToken());
    return this.http.put(url, data, {headers: headers}).pipe(
      takeUntil(this.globalService.isSessionExist),
      map((res: any) => {
        return res['data'];
      })
    );
  }*/

  public getRequest(url: any, params = {}) {
    const headers: HttpHeaders = new HttpHeaders()
      .set("device", "WEB")
      .set("Authorization", "Bearer " + this.globalService.getAccessToken());
    return this.http.get(url, {
      params: params,
      headers: headers
    }).pipe(takeUntil(this.globalService.isSessionExist), map((res: any) => {
        return res['data'];
      }),
      catchError(err => {
        return this.handleError(err);
      })
    );
  }

  public getRequestWithoutErrorMessage(url: any, params = {}) {
    const headers: HttpHeaders = new HttpHeaders()
      .set("device", "WEB")
      .set("Authorization", "Bearer " + this.globalService.getAccessToken());
    return this.http.get(url, {
      params: params,
      headers: headers
    }).pipe(takeUntil(this.globalService.isSessionExist), map((res: any) => {
        return res['data'];
      })
    );
  }


  public handleError(error: HttpErrorResponse) {
    if (error instanceof HttpErrorResponse) {
      if (error.status === 401 && error.error && error.error.type !== 'INVALID_CREDENTIALS') {
        StorageService.clearAll();
        this.globalService.setIsSessionExist();
        this.alertService.showErrors('Session Expired!');
      }
    }
    if (error instanceof ErrorEvent) {
      //console.error('An error occurred:', error?.error?.message);
    } else {
      /*console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);*/
    }
    switch (error.error.type) {
      case 'INTERNAL_SERVER_ERROR':
      case 'RESOURCE_NOT_FOUND':
      case 'FORBIDDEN_ERROR':
      case 'INVALID_CREDENTIALS':
      case 'BUSINESS_LOGIC_ERROR':
      case 'BAD_REQUEST':
        this.alertService.showErrors(error.error.error);
        return throwError(error.error.error);
      case 'VALIDATION':
        const errorMessageObject = error.error.message;
        this.alertService.showErrors(this.returnArrayOfErrors(errorMessageObject));
        return throwError(this.returnArrayOfErrors(errorMessageObject));
    }
    return throwError(error);
  }

  public returnArrayOfErrors(errorMessageObject: any) {
    const parsedArray: any = [];
    Object.keys(errorMessageObject).forEach(message => {
      const keyName = (message.split('.').join(' ')).replace(/([A-Z])/g, ' $1').replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
      const description = this.joinMessages(errorMessageObject[message]).split('.').join(' ');
      const errorObject = {
        keyName,
        description,
        completeMessage: `${keyName} : ${description}`
      };
      parsedArray.push(errorObject);
    });
    return parsedArray;
  }
}

